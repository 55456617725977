<template >
    <div class="watistantra ">

        <div class="watistantra1 row gx-5">
            <h1>Wat is sensuele tantra?</h1>
            <div class="col-md-4">
                <h2>Introductie en <br>omgeving</h2>
                <p>Een sensuele tantra massage is een diepgaande ervaring van verbinding, aanraking en ontspanning. In deze
                    unieke vorm van massage staat niet alleen het fysieke genot centraal, maar ook het spirituele en
                    energetische aspect. Het draait om het bewust worden en ontwaken van de zintuigen, het loslaten van
                    spanningen en het openen van de geest.

                    Bij het geven en ontvangen van een sensuele tantra massage draait alles om volledige aanwezigheid en
                    overgave. Het begint met een rustige en respectvolle omgeving, waarin beide partijen zich veilig en
                    comfortabel voelen. De massage wordt uitgevoerd met warme, natuurlijke oliën die helpen om de zintuigen
                    te prikkelen en de huid te verzachten.</p>



            </div>
            <div class="col-md-4">
                <h2>Techniek en <br>aanraking</h2>
                <p>De aanrakingen tijdens een tantra massage zijn langzaam, vloeiend en doelbewust. Ze verkennen het hele
                    lichaam, van top tot teen, waarbij speciale aandacht wordt besteed aan het opwekken van sensuele
                    energieën. De masseur of masseuse gebruikt niet alleen zijn/haar handen, maar ook zijn/haar hele lichaam
                    om een diep gevoel van verbondenheid te creëren.</p>
            </div>
            <div class="col-md-4">
                <h2>Effecten en <br>intentie</h2>
                <p>Een sensuele tantra massage kan een diep helende werking hebben, zowel op fysiek als emotioneel niveau.
                    Het kan helpen bij het loslaten van stress, het verhogen van het libido en het bevorderen van
                    zelfacceptatie en liefde voor het eigen lichaam.

                    Het is belangrijk om te benadrukken dat een tantra massage niet gaat om seksuele bevrediging of
                    prestatie, maar om het cultiveren van een dieper begrip van het lichaam en de geest. Het vraagt om
                    respect, geduld en openheid van zowel de gever als de ontvanger. In een wereld vol haast en afleiding,
                    biedt sensuele tantra massage een kostbare gelegenheid om in het moment te zijn en de verborgen schatten
                    van de zintuigen te verkennen.</p>
            </div>
        </div>
    </div>
   
</template>




<script>
export default {
    name: 'WatView',
    
}
</script>







<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

p {
    font-family: 'Cormorant', serif;
}

.watistantra {
    background-color: black;
    color: rgb(255, 251, 251);
    padding: 2rem;
    padding-top: 20rem;
    text-align: left;
}

.watistantra1 {
    margin: 0 auto;
    max-width: 1600px;
}

.watistantra1 h1 {
    font-size: 4rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: #ecd9a6;

}


.watistantra1 h2 {
    font-size: 2.5rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 4px solid #CBA135;
}

.watistantra1 p {
    font-size: 1.5rem;
    line-height: 2rem;
}


.aanbod h2 {
    margin-bottom: 3rem;
    text-align: center;
    display: block;
    
    
    


}
.card {
    transition: transform 0.3s;
    cursor: pointer;
    margin-bottom: 20px; /* Adds some space between the cards */
    
}

.card:hover {
    transform: scale(1.05);
}

.card-img-top {
    width: 100%;
    height: 15vw;
}

.moreInfoBtn{
    color: grey;
    text-decoration: none;
}



.card-link {
    text-decoration: none;
}   


@media screen and (max-width: 768px){
    .watistantra {
        padding-top: 10rem;
        box-sizing: border-box;
        
    }

    .watistantra1 h1 {
        font-size: 2.3rem;
        max-width: 110% !important;
        width: 120% !important;
    }

    .watistantra1 h2 {
        font-size: 1.5rem;
        
    }

    .watistantra1 p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    
}

</style>