<template >
  <div class="home">
  <div>
    <div class="banner d-flex justify-content-center align-items-center">  
    </div>

    <img v-if="isWideScreen" src="..\assets\wave3.png" alt="wave" class="wave">
    <img v-else src="..\assets\wave3-1.png" alt="wave" class="wave">

    <div class="intro1 d-flex justify-content-center align-items-center">
      <div class="intro1section col-md-6">
        <h2>Sensueel genot: Een Ode aan de Zintuigen</h2>
        <p class="text-left">Sensueel genot is als een meeslepende symfonie van zintuiglijke verleidingen. Het begint
          met de tedere streling van de huid, een subtiele aanraking die rillingen van opwinding veroorzaakt. Het
          vervolgt met de bedwelmende geur van bloesems die de lucht vult, een zoete belofte van verrukking. Dan is er
          de smaak van verboden vruchten, zacht en weelderig op de tong dansend. Elk moment, elke sensatie, is een
          viering van het menselijk vermogen om te genieten van de geneugten van het leven.
          <br>
          <br>
          In het rijk van sensueel genot, zijn er alleen de vrije uitdrukking van verlangen en bevrediging. Het is een
          dans van intimiteit en verrukking, waarin elke aanraking een verhaal vertelt van passie en plezier. Het is een
          herinnering aan het belang van het koesteren van onze zintuigen, van het waarderen van de eenvoudige genoegens
          die het leven te bieden heeft.
          <br><br>
          Dus laten we ons overgeven aan de verleiding van sensueel genot, laten we ons laten meeslepen door de
          betovering van elk moment. Want in die betovering ontdekken we de ware essentie van het leven - een eindeloze
          viering van liefde, plezier en verrukking.
        </p>
      </div>
      <div class="col-md-5">
        <img src="..\assets\buddha.png" alt="Card 1" class="img-fluid">
      </div>

    </div>
  </div>
</div>


  
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isWideScreen() {
      return this.windowWidth > 768;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');



h2 {
  font-family: 'Playfair Display', serif;
}

p {
  font-family: 'Cormorant', serif;
}

.banner {
  background-image: url('../assets/Banner2.png');
  background-position: center;
  background-size: cover;
  
  background-repeat: no-repeat;
  height: 600px;
  color: white;
  font-family: 'Cormorant', serif;
}



.banner h2 {
  font-size: 6rem;
  font-weight: 700;
}

.banner img {
  width: 40%;
  
  border-radius: 80px ;
  position: absolute;
  border: 3px solid #ffffff;
  top: 12rem
}

.welkom {
  text-align: left;

}

button {
  background-color: #cca236;
  color: rgb(255, 255, 255);
  font-size: 1.4rem;

  border: none;
  border-radius: 5px;
  margin-top: 1em;
  cursor: pointer;
}

button:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: #FFD700;
}


.wave {
  width: 100%;
}   

.moreInfoBtn {
  background-color: #cca236;
  color: rgb(255, 255, 255);

  padding: 7px;
  border: none;
  border-radius: 9px;
  margin-top: 1em;
  cursor: pointer;

}

.info {
  background-color: #ffffff;

}

.title {
  font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #000000;
  text-align: left;
  padding-left: 6rem;
}

.gold-line {
  border: 4px solid #CBA135;
  width: 100px;
  margin: 20px 6rem;
  /* Hier heb ik auto vervangen door 0 */
  opacity: 1;

}




.intro1 {
  padding: 2px;


  border-radius: 20px;
  
}

.intro1 h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 4px solid #CBA135;
}

.intro1 p {
  font-size: 1.2rem;
  font-weight: 300;
}

.intro1 img {
  width: 90%;
  max-width: 900px;
  border-radius: 20px;
  


}

.intro1section {
  text-align: left;
  padding: 50px;

  border-radius: 20px 0px 0px 20px;

  margin-right: -2rem;


}



.intro2 {
  padding: 50px;
}

.intro2 h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.intro2 p {
  font-size: 1.2rem;
  font-weight: 300;
}

.intro2 img {
  width: 80%;
  max-width: 900px;
  border-radius: 0% 50% 0% 50%;

}

.intro2section {
  text-align: left;
  padding: 50px;
  background-color: white;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-left: -7rem;
  margin-bottom: -9rem;
  z-index: -1;

}



article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}


article h2 {
  margin: 0 0 18px 0;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}





.articles {
  display: grid;
  max-width: 1750px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {

  .banner{
    background-image: url('../assets/Banner2-1.png');
  
    height: 400px;
    
  }
  article {
    container: card/inline-size;
  }

  .article-body p {
    display: none;
  }

  .intro1 {
    
    display:block !important;
  }

  .intro1 h2 {
    font-size: 1.5rem;
  }
  
  .intro1section{
    margin-right: 0;
    padding-top: 10px !important;
  }


}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .article-body {
    padding-left: 0;
  }

  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
