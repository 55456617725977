<template>
  <div id="app">
    <NavigationBar />
    <router-view />
    <!-- <FooterBar /> -->

  </div>
</template>

<script>
import NavigationBar from './components/navigation/Navigation.vue'
// import FooterBar from './components/footer/Footer.vue'


export default {
  name: 'App',
  components: {
    NavigationBar,
    // FooterBar

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {

  font-family: "Figtree", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-image: linear-gradient(45deg, #c4b48d, #ffffff);
  min-height: 100vh;
}
</style>
