<template>
  <div v-if="showArrow" ref="arrow" class="scroll-arrow" @click="scrollToAgenda">
      Bekijk beschikbare momenten
    </div>
  <div class="container">
    <div class="row">
      <h1>Contacteer ons</h1>
    </div>
    <div class="row">
      <h4 style="text-align:center">
        Contacteer ons via dit formulier of via het telefoonnummer: <strong class="strong"> +32495618931</strong>
        <br> of via email: <strong class="strong"> sensualpleasuretantra@gmail.com</strong>
      </h4>
    </div>
    <form @submit.prevent="submitForm">
      <div class="row input-container">
        <div class="col-xs-12">
          <div class="styled-input wide">
            <input type="text" v-model="formData.name" required />
            <label>Naam</label>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="styled-input">
            <input type="email" v-model="formData.email" required />
            <label>Email</label>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="styled-input" style="float:right;">
            <input type="text" v-model="formData.phone" required />
            <label>Telefoon nummer</label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="styled-input wide">
            <textarea v-model="formData.message" required></textarea>
            <label>Bericht</label>
          </div>
        </div>
        <div class="col-xs-12">
          <button type="submit" class="btn-lrg submit-btn">Verstuur</button>
        </div>
      </div>
    </form>
  </div>

  <div class="google-calender" ref="googlecalender">
    <h4 style="text-align:center">
        Hieronder vind u de momenten die nog beschikbaar zijn om een sessie te boeken adhv bovenstaande formulier.<br>
      </h4>
      <h4>Klik op het beschikbare moment om de details te bekijken.</h4>
    <iframe
      v-if="isLargeScreen"
      src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FBrussels&bgcolor=%23000000&showPrint=0&showTitle=0&showTabs=0&showCalendars=0&showTz=0&src=Y18wNTY4Nzg5NDE2NjYxNmMyMmYyNmUxZjUwOTU3ZDdkMjhmMWU5MDY3Nzk5MmEyODk5OTkzNjNkZThhZWYyMWIxQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237986CB"
      style="border:solid 1px #777" width="1000" height="500" frameborder="0" scrolling="no">
    </iframe>



    <iframe v-else src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FBrussels&bgcolor=%23000000&mode=AGENDA&showTitle=0&showPrint=0&showTabs=0&showTz=0&src=Y18wNTY4Nzg5NDE2NjYxNmMyMmYyNmUxZjUwOTU3ZDdkMjhmMWU5MDY3Nzk5MmEyODk5OTkzNjNkZThhZWYyMWIxQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237986CB" style="border:solid 1px #777" width="350" height="600" frameborder="0" scrolling="no"></iframe>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactView',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      showArrow: true,
      isLargeScreen: window.innerWidth > 800 // Initialize based on current screen size
    };
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize); // Add event listener to detect screen resize
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize); // Clean up the event listener
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 800; // Update the isLargeScreen flag based on screen width
    },
    async submitForm() {
      try {
        const response = await emailjs.send('service_o9malbk', 'template_i5980b9', this.formData, 'vi-pw5wMVFj3-WFS4');

        if (response.status === 200) {
          console.log('Email successfully sent!');
          alert('Uw contact is verzonden! Wij bekijken uw bericht zo snel mogelijk en nemen contact met u op als dat nodig is. Bedankt!');

          // Reset the form after sending
          this.formData = {
            name: '',
            email: '',
            phone: '',
            message: ''
          };
        } else {
          throw new Error('Failed to send email');
        }
      } catch (error) {
        console.error('There was a problem with the email sending operation:', error);
        alert('Failed to send email. Please try again later.');
      }
    }
    ,
    scrollToAgenda() {
      const element = this.$refs.googlecalender;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      const element = this.$refs.googlecalender;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition >= element.offsetTop) {
        this.showArrow = false;
      } else {
        this.showArrow = true;
      }
    }
  }
 
  
  
};
</script>

<style scoped>
body {
  background-color: #444442;
  padding-top: 85px;
}

.container {
  padding-top: 14rem;
  padding-bottom: 5rem;
  background-color: black;
  max-width: 100% !important;
}

h1 {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 72px;
  color: #CFA435;
  text-align: center;
}

h4 {
  font-family: 'Roboto', sans-serif, 'arial';
  font-weight: 400;
  font-size: 20px;
  color: #9b9b9b;
  line-height: 1.5;
}

/* ///// inputs /////*/

input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

.strong {
  color: #CFA435;

}

@media only screen and (max-width: 768px) {
  .styled-input {
    width: 100%;
  }
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 10px;
  display: inline-block;
  background-color: #CFA435;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10),
    0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media (max-width: 768px) {
  .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }

  h1 {
    font-size: 48px;
  }
}

input[type=checkbox]+label {
  color: #ccc;
  font-style: italic;
}

input[type=checkbox]:checked+label {
  color: #f00;
  font-style: normal;
}

/* Google Calendar Styles */

.google-calender {
  padding-bottom: 5rem;
  background-color: black;
  max-width: 100% !important;
  padding: 3px;
}

.google-calender iframe {
  border: none !important;
}

/* Scroll arrow styling */
.scroll-arrow {
  position: fixed;
  top: 93%;
  right: 30px;
  background-color: #CFA435;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.5s ease; /* Voeg deze regel toe */
  opacity: 1; /* Begin met volledig zichtbaar */
}
.scroll-arrow.hidden {
  opacity: 0; /* Voeg deze regel toe */
}

.scroll-arrow:hover {
  opacity: 0.8;
}
</style>
